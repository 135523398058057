<!--
 * @Author: yuwenwen
 * @Date: 2022-11-10 16:30:58
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-01-06 11:15:25
 * @FilePath: \nc-funeral-mobile\src\views\products\shopCart.vue
-->
<template>
  <div class="page-container shop-cart-container">
    <!-- 购物车管理 -->
    <div class="product-mana">
      <div>共{{ shopcartNum }}件商品</div>
      <div v-if="!isManage" class="mana-btn" @click="changeStatus(true)">
        管理
      </div>
      <div v-if="isManage" class="mana-btn" @click="changeStatus(false)">
        完成
      </div>
    </div>
    <!-- 商品列表 -->
    <div class="product-list">
      <div
        class="product-item"
        v-for="(item, index) in shopCartList"
        :key="index"
      >
        <van-checkbox
          v-model="item.check"
          checked-color="#78BCA5"
          icon-size="22px"
          @change="setCheck"
        ></van-checkbox>
        <img class="product-img" :src="item.picture" />
        <div class="product-price-name">
          <div class="name">{{ item.chargeName }}({{ item.specs }})</div>
          <div class="price"><span>¥</span> {{ item.salesPrice }}</div>
          <!-- <div class="stock">库存{{ item.surplusStock }}</div> -->
        </div>
        <div class="add-to-shop">
          <img
            src="../../assets/images/s-reduce.png"
            @click.stop="changeShopcartData(item, 'reduce')"
          />
          {{ item.cartNum }}
          <img
            src="../../assets/images/s-add.png"
            @click.stop="changeShopcartData(item, 'add')"
          />
        </div>
      </div>
    </div>

    <!-- 底部操作栏 -->
    <div class="opration-box">
      <div class="checkbox">
        <van-checkbox
          checked-color="#78BCA5"
          v-model="checked"
          icon-size="20px"
          @click="setAllCheck"
          >全选</van-checkbox
        >
      </div>
      <div class="total-btn" v-if="!isManage">
        <div class="total">
          合计：<span class="symbol">¥</span
          ><span class="price">{{ payMoney }}</span>
        </div>
        <div class="pay-btn" @click="payOrder">支付订单</div>
      </div>

      <div class="total-btn" v-if="isManage">
        <div class="pay-btn del-btn" @click="deleteProduct">删除</div>
      </div>
    </div>

    <!-- 下单用户信息 -->
    <van-popup
      v-model:show="payUserPopup"
      position="bottom"
      class="pay-info-popup"
    >
      <div class="popup-header">
        下单信息
        <van-icon
          class="close-icon"
          name="cross"
          @click="payUserPopup = false"
        />
      </div>
      <van-form>
        <div class="title-box">
          <div class="title-text">购买人信息</div>
        </div>
        <div class="line-item">
          <div class="label-text">姓名</div>
          <van-field
            v-model.trim="orderUser.buyName"
            name="请输入您的姓名"
            placeholder="请输入您的姓名"
          />
        </div>
        <div class="line-item">
          <div class="label-text">电话</div>
          <van-field
            v-model.trim="orderUser.buyMobile"
            name="请输入电话"
            placeholder="请输入电话"
          />
        </div>
        <div class="line-item">
          <div class="label-text">与逝者关系</div>
          <van-field
            v-model="displayRelation"
            name="picker"
            placeholder="请选择"
            is-link
            readonly
            @click="showPicker = true"
          />

          <van-popup v-model:show="showPicker" position="bottom">
            <van-picker
              title="与逝者关系"
              :columns="relationship"
              @confirm="relationConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
        </div>
        <div class="title-box">
          <div class="title-text">逝者信息</div>
        </div>
        <div class="line-item">
          <div class="label-text">姓名</div>
          <van-field
            v-model.trim="orderUser.deadName"
            name="请输入逝者姓名"
            placeholder="请输入逝者姓名"
          />
        </div>
        <div class="line-item">
          <div class="label-text">身份证号码</div>
          <van-field
            v-model.trim="orderUser.deadCardCode"
            name="请输入逝者身份证号码"
            placeholder="请输入逝者身份证号码"
          />
        </div>
      </van-form>

      <div class="popup-footer">
        <div class="pay-btn-pay" @click="confirmOrder">支付</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { orderConfirm, orderCreate } from "@/api/product.js";
import { initPayConfig, NumberMul, NumberAdd } from "@/utils/funeral.js";
import { showToast, showConfirmDialog } from "vant";
import {getDicts} from '@/api/common.js'
export default {
  name: "ShopCart",
  data() {
    return {
      productList: [],
      checked: false,
      isManage: false,
      payMoney: 0,
      payUserPopup: false,
      showPicker: false,
      orderUser: {},
      displayRelation: "",
      relationship: [],
      payList: [],
    };
  },
  computed: {
    shopCartList: {
      get() {
        return this.$store.state.product.shopCartList;
      },
      set(val) {
        return val;
      },
    },
    shopcartNum: {
      get() {
        return this.$store.state.product.shopcartNum;
      },
      set(val) {
        return val;
      },
    },
  },
  created() {
    console.log(this.shopCartList);
    this.getRelationDict();
    // 给每一项添加check字段
    this.shopCartList.forEach((item) => {
      item.check = false;
    });
  },
  mounted() {},
  methods: {
    // 获取与逝者关系数据字典
    getRelationDict(){
      getDicts('relation').then(res=>{
        this.relationship = res;
      })
    },
    // 确认订单
    async confirmOrder() {
      let params = {
        orderUser: { ...this.orderUser },
        productDetailsInfos: this.payList.map((item) => {
          return { productId: item.priceId, totalNum: item.cartNum };
        }),
      };
      let [err, res] = await this.$awaitWrap(orderConfirm(params));
      if (err) {
        return;
      }
      console.log(res);
      this.createOrder(res.data.uniqueKey, params);
    },

    // 创建订单
    async createOrder(uniqueKey, data) {
      let params = {
        uniqueKey: uniqueKey, //订单唯一标识，后台自动生成，防止订单重复提交。
        orderSource: "GZH", //订单来源
        payWay: "WX_PAY", //支付方式
        remarks: "订单备注", //订单备注
        ...data,
      };
      console.log(params);
      let [err, res] = await this.$awaitWrap(orderCreate(params));
      if (err) {
        return;
      }
      if (res.data.payParams) {
        initPayConfig(res.data.payParams).then(() => {
          this.$router.push({
            path: "/product/order/details",
            query: { orderNo: res.data.orderNo },
          });
        });
      }
      this.removePayedDataFromStore();
    },

    // 已支付的订单从store中去除
    removePayedDataFromStore() {
      let storeList = this.shopCartList;
      storeList.forEach((item, index) => {
        this.payList.forEach((product) => {
          if (item.priceId == product.priceId) {
            storeList.splice(index, 1);
          }
        });
      });
      //已支付的订单从store中去除
      this.$store.dispatch("setShopCartList", storeList);
    },

    // 全选
    setAllCheck() {
      this.shopCartList.forEach((item) => {
        item.check = this.checked;
      });
      this.computedTotalPrice();
    },
    // 单个选项与全选之间的联动
    setCheck() {
      this.checked = this.shopCartList.every((item) => {
        return item.check;
      });
      this.computedTotalPrice();
    },

    // 计算商品价格
    computedTotalPrice() {
      this.payList = [];
      let payMoney = 0;
      this.shopCartList.forEach((item) => {
        console.log(item);
        if (item.check) {
          this.payList.push(item);
          payMoney = NumberAdd(
            payMoney,
            NumberMul(parseFloat(item.salesPrice), item.cartNum)
          );
        }
      });
      this.payMoney = payMoney;
    },
    // 支付订单按钮
    payOrder() {
      if (this.payList.length > 0) {
        this.payUserPopup = true;
      } else {
        showToast("请选择需要支付的商品");
      }
    },

    // 管理商品按钮
    changeStatus(status) {
      this.isManage = status;
    },
    // 删除商品
    deleteProduct() {
      let storeList = this.shopCartList;
      let delList = storeList.filter((item) => {
        return item.check;
      });
      if (delList.length > 0) {
        showConfirmDialog({
          title: "提示",
          message: "是否确认删除勾选的商品？",
        })
          .then(() => {
            storeList = storeList.filter((item) => {
              return !item.check;
            });
            this.$store.dispatch("setShopCartList", storeList);
            this.computedTotalPrice();
          })
          .catch(() => {});
      } else {
        showToast("请勾选需要删除的商品");
      }
    },
    // 购物车加减
    changeShopcartData(item, type) {
      let storeList = this.shopCartList;
      storeList.forEach((product) => {
        if (item.priceId == product.priceId) {
          // 添加不能超过库存
          if (type == "add") {
            if (item.cartNum >= item.surplusStock) {
              showToast("不能超过库存");
            } else {
              product.cartNum = product.cartNum + 1;
            }
          } else {
            if (item.cartNum <= 1) {
              showToast("商品件数最小为 1");
            } else {
              product.cartNum = product.cartNum - 1;
            }
          }
          this.$store.dispatch("setShopCartList", storeList);
          this.computedTotalPrice();
        }
      });
    },
    // 选择与逝者关系
    relationConfirm(item) {
      this.displayRelation = item.selectedOptions[0].text;
      this.orderUser.relation = item.selectedOptions[0].value;
      this.showPicker = false;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.van-field__control {
  color: rgba(0, 0, 0, 1);
  font-size: 17px;
  font-family: PingFang-SC-Bold;
  text-align: right;
}
.van-cell:after {
  border: 0;
}
.van-cell {
  padding: 0;
}
/deep/.van-uploader__upload {
  margin: 0;
}
.del-btn{
  background: #FF0000 !important;
}
@import "../../assets/styles/product.less";
</style>